import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Card,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Box,
  FormControl,
  TextField,
  CardContent,
  Snackbar,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import image from "../../../images/payments.png";
import DepositHistory from "../../../components/DepositHistory";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  wallet: {
    backgroundColor: "#12101c",
    color: "white",
    minHeight: "100vh",
  },
  container: {
    paddingLeft: 40,
    paddingRight: 40,
    color: "white",
  },
  card: {
    minWidth: 275,
    backgroundColor: "#201d36",
    color: "white",
  },
  fund: {
    backgroundColor: "#29b352",
  },
  title: {
    fontSize: 18,
    fontFamily: "Montserrat",
  },
  title1: {
    fontSize: 14,
    paddingTop: 15,
    fontFamily: "Montserrat",
    color: "gold",
  },
  text: {
    marginTop: theme.spacing(2),
    color: "gold",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
  },
}));

function Fund() {
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const [newAmount, setNewAmount] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [description, setDescription] = useState("");

  const { currentUser } = useAuth();
  let dbStateRef = React.useRef();
  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    if (currentUser) {
      const unsub = onSnapshot(
        doc(db, "wallets", "lTqrBcpAzRxr7dDMQukR"),
        (doc) => {
          dbStateRef.current = doc.data();
          setDbState(dbStateRef.current);
        }
      );

      return () => unsub();
    }
  }, [currentUser]);

  const BTC = dbState?.btcAddress || "";
  const ETH = dbState?.ethAddress || "";

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleAmountChange = (event) => {
    setNewAmount(event.target.value);
  };

  const handleTransactionHashChange = (event) => {
    setTransactionHash(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const createFund = async () => {
    // await addDoc(createFundRef, {
    //   depositAmount: newAmount,
    //   transactionHash: transactionHash,
    //   description: description,
    // });
    setOpenAlert(true);
  };

  const isFormValid = newAmount && transactionHash && description;

  return (
    <div className={classes.wallet}>
      <Header />
      <Container className={classes.container} fixed>
        <Box sx={{ height: "5vh" }} />
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              Wallet Deposit Address
            </Typography>
            <img src={image} alt="payments" height="140" />
            <Typography className={classes.title1} gutterBottom>
              Please Note that Minimum Fund Amount is $1,000 or its equivalent.
              Contact vinex@californiamail.com for a more detailed description.
              Your account will be credited once your payment is confirmed.
            </Typography>
            {/* Form */}
            <form>
              {/* Fund Code */}
              <Typography className={classes.title1} gutterBottom>
                Fund Code
              </Typography>
              <TextField
                className={classes.text}
                disabled
                id="outlined-disabled"
                label="Fund Code"
                defaultValue="Gtrdx534T"
                variant="outlined"
                fullWidth
              />
              {/* Amount */}
              <Typography className={classes.title1} gutterBottom>
                Amount
              </Typography>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">
                  Amount
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  type="number"
                  value={newAmount}
                  onChange={handleAmountChange}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  labelWidth={60}
                />
              </FormControl>
              {/* Payment Addresses */}
              <Typography className={classes.title1} gutterBottom>
                Please Send Payments to any of the addresses provided below
              </Typography>
              {/* Bitcoin Address */}
              {BTC && (
                <div>
                  <Typography className={classes.title1} gutterBottom>
                    Bitcoin Address
                  </Typography>
                  <TextField
                    className={classes.text}
                    disabled
                    id="Bitcoin Address"
                    label="Bitcoin Address"
                    value={BTC}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              )}
              {/* Ethereum Address */}
              {ETH && (
                <div>
                  <Typography className={classes.title1} gutterBottom>
                    Ethereum Address
                  </Typography>
                  <TextField
                    className={classes.text}
                    disabled
                    id="Ethereum Address"
                    label="Ethereum Address"
                    value={ETH}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              )}
              {/* Additional Information */}
              <Typography className={classes.title1} gutterBottom>
                Deposits to this address are unlimited. Note that you may not be
                able to withdraw all of your funds at once if you deposit more
                than your daily withdrawal limit.
              </Typography>
              {/* Transaction Hash ID */}
              <Typography className={classes.title1} gutterBottom>
                Transaction Hash ID
              </Typography>
              <TextField
                className={classes.text}
                id="Transaction Hash ID"
                label="Enter Transaction Hash ID"
                variant="outlined"
                fullWidth
                value={transactionHash}
                onChange={handleTransactionHashChange}
              />
              {/* Description */}
              <Typography className={classes.title1} gutterBottom>
                Description
              </Typography>
              <TextField
                className={classes.text}
                id="Description"
                label="Enter Description"
                variant="outlined"
                fullWidth
                value={description}
                onChange={handleDescriptionChange}
              />
            </form>
          </CardContent>
          {/* Fund Button */}
          <Button
            variant="contained"
            className={classes.fund}
            onClick={createFund}
            disabled={!isFormValid}
          >
            Fund
          </Button>
        </Card>
        {/* Snackbar */}
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="success">
            Payment Processing! Updates will appear on your dashboard shortly.
            Any delay in processing, please contact admin with proof of payment.
          </Alert>
        </Snackbar>
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            Deposit History
          </Typography>
        </CardContent>
        <div className="withdraw">
          <DepositHistory />
        </div>
      </Container>
    </div>
  );
}

export default Fund;
